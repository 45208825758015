.dashboard-progress-card {
  &-text,
  &-text-last {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    &-paragraph {
      margin-bottom: 0 !important;
    }

    &-title {
      margin-bottom: 0 !important;
      line-height: 30px !important;
    }
  }

  &-text {
    margin-bottom: 12px;
  }
}

.dashboard-chart-card {
  &-title {
    margin-bottom: 0 !important;
  }
  & > .ant-card-body {
    padding-top: 0;
  }
}

.dashboard-tabs {
  .ant-tabs-nav {
    margin-bottom: 8px;
  }

  .ant-tabs-nav-wrap {
    background-color: #ffffff;
    padding: 0 8px;

    .ant-tabs-tab {
      padding: 9px 12px 10px 12px;
    }
  }
}

.dashboard-special-card {
  &-table {
    margin-top: 12px;
  }
}

.dashboard-model-statistics-card {
  &-input-wrapper {
    display: flex;
    justify-content: space-between;
  }
}
