.ticket-messages {
  .ant-list-header {
    padding: 0 0 8px 0;

    h4 {
      margin: 0;
    }
  }
}

.tickets-file-list {
  display: flex;
  margin-top: 12px;

  & > *:not(:last-child) {
    margin-right: 12px;
  }
}

.tickets-file-list-item {
  width: 124px;

  .ant-card-meta-title {
    font-size: 13px;
    direction: rtl;
    text-align: left;
  }

  .ant-card-cover {
    .anticon {
      font-size: 16px;
      margin-top: 12px;
    }
  }
}

.ticket-message-item-management {
  .ant-comment-inner {
    flex-direction: row-reverse;

    .ant-comment-content {
      text-align: right;
    }

    .tickets-file-list {
      justify-content: flex-end;
    }

    .ant-comment-content-author {
      flex-direction: row-reverse;
    }
  }
}
