.bonaz-categories {
  p {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 12px;
    margin-bottom: 12px;

    &:last-child {
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: none;
    }
  }
}
