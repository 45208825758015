.declarations-accept-container {
  display: flex;
  align-items: center;
  flex-direction: column;

  .declarations-accept-base-input {
    width: 200px;
  }

  .declarations-accept-info-container {
    margin-top: 24px;
  }

  .declarations-accept-checkbox {
    margin-top: 12px;
  }
}
