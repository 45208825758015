.ant-next-form-small-space {
  .ant-row.ant-form-item {
    margin-bottom: 18px;
  }

  .ant-next-form-item-checkbox-small {
    & > .ant-form-item-control > .ant-form-item-control-input {
      min-height: 22px;
    }
  }
}

.form-error-alert {
  margin-bottom: 18px;
}

.radio-input-column-view {
  & > .ant-radio-wrapper {
    display: block;
  }
}

.radio-input-column-scale {
  & > .ant-radio-wrapper {
    transition: padding-left 0.2s ease-in-out;
    &:hover {
      padding-left: 8px;
    }
  }
}

.textarea-additional {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  padding: 4px 8px;
  border: 1px solid #00000025;
  border-top: none;
  background-color: #00000002;
}
