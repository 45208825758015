.logs-table {
  width: 100%;

  td,
  th {
    padding: 12px;
    border: 1px solid #f0f0f0;
  }

  &-field {
    background-color: #f4f4f4;
  }

  &-old-value {
    text-decoration: line-through;
  }
}
