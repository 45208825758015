.next-table-outer {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
}

.next-table-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
}

.next-table {
  border: 1px solid #f0f0f0;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;

  &-filter {
    width: 100%;
    text-align: left;
  }

  & > .next-table-thead {
    & > .next-table-tr {
      & > .next-table-th {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 8px;
        color: rgba(0, 0, 0, 0.85);
        background-color: #fafafa;
        font-weight: 500;
        border-bottom: 1px solid #f0f0f0;
        border-right: 1px solid #f0f0f0;
        cursor: pointer;
        transition: background-color 0.3s;
        &:hover {
          background-color: #f0f0f0;
        }
      }
    }
  }

  & > .next-table-tbody {
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;

    & > .next-table-tr {
      &.next-table-tr-selected {
        background-color: #e6f7ff !important;

        &:hover {
          background-color: #d2ecff !important;
        }
      }

      transition: background-color 0.3s;

      &:hover {
        background-color: #fafafa;
      }

      & > .next-table-td {
        display: flex;
        align-items: center;
        padding: 8px;
        color: rgba(0, 0, 0, 0.85);
        border-right: 1px solid #f0f0f0;
        cursor: pointer;
        border-bottom: 1px solid #f0f0f0;
      }
    }
  }

  &-action-btn {
    background-color: #fafafa;
    margin: -8px -5px;
  }

  &-loading-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.5);
    z-index: 2;
  }
}

.next-table-pagination {
  padding: 8px;
  background-color: #fafafa;
}

.table-info-cell {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &-icon {
    margin-left: 8px;
  }
}
