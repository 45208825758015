.barcode-scan-container {
  display: flex;
  justify-content: center;
  align-items: center;

  & > .barcode-scan {
    position: relative;
    width: 200px;

    & > .values {
      text-align: center;
      margin-top: -4px;
    }

    & > .lines {
      display: flex;
      justify-content: space-between;
      height: 80px;

      @for $i from 1 through 24 {
        & > div:nth-child(#{$i}) {
          $w: random(5);
          width: #{$w}px;
          background-color: #000;
        }
      }
    }

    & > .scanner {
      animation: scanningAnimation 2s infinite;

      & > .laser {
        position: absolute;
        top: 0;
        left: 50%;
        height: 1px;
        width: calc(100% + 50px);
        background-color: red;
        transform: translateX(-50%);
        box-shadow: 0 0 4px red;
        animation: beam 0.1s infinite;
      }
    }
  }

  @keyframes beam {
    50% {
      opacity: 0.7;
    }
  }

  @keyframes scanningAnimation {
    0% {
      transform: translateY(10px);
    }

    50% {
      transform: translateY(-60px);
    }

    100% {
      transform: translateY(10px);
    }
  }
}
