.shops-page {
  &-tab {
    margin-top: -8px;

    .ant-tabs-tab {
      padding: 12px;
    }

    .ant-tabs-nav {
      margin-bottom: 8px;
    }
  }

  &-card {
    .ant-card-cover {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 250px;
      max-height: 250px;
      height: 250px;
      overflow: hidden;
    }

    .ant-card-meta-description {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
