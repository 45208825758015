@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

.full-width {
  width: 100%;
}

.paragraph-no-space {
  margin-bottom: 0 !important;
}

.actions-wrapper {
  display: flex;
  flex-wrap: wrap;

  &-left,
  &-right {
    flex: 1;
    display: flex;
    margin-bottom: 8px;
  }

  &-left {
    justify-content: flex-start;
  }

  &-right {
    justify-content: flex-end;
  }

  &-additional-button {
    margin-left: 8px;
  }

  &-selection-button {
    &:hover,
    &:focus,
    &:active {
      border-color: #ff4d4f;
      color: #ff4d4f;
    }
  }
}

.state-timeline {
  padding: 28px;

  .ant-timeline-item-head {
    .anticon {
      font-size: 24px;
      vertical-align: 2px;
    }
  }

  .ant-timeline-item {
    padding-bottom: 48px;

    &:last-child {
      padding-bottom: 0;
    }
  }
}

.state-timeline-skeleton-wrapper {
  padding: 24px;
  margin-bottom: -1px;
}

.state-timeline-skeleton:not(:last-child) {
  margin-bottom: 27px;
}

.info-skeleton-wrapper {
  padding: 24px;
  margin-bottom: -9px;
  width: 100%;
}

.info-skeleton-button-wrapper {
  display: flex;

  &:not(.no-margin) {
    margin-top: 18px;
  }

  .info-skeleton-button {
    display: block;
    flex: unset;
    width: 100px;
    &:not(:last-child) {
      margin-right: 12px;
    }
  }
}

.input-addon-button {
  height: 30px;
  padding: 0 10px 0 0;
  margin-right: 10px;
  border-right: 1px solid #e9e9e9;
}

.absolute-spinner,
.absolute-result,
.absolute-empty {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}

.absolute-empty {
  pointer-events: none;
}

.upload-only-button {
  & > span {
    display: block;

    & > .ant-upload {
      display: block;

      & > span {
        display: block;
      }
    }
  }
}

.flex-center {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.vertical-tabs {
  .ant-tabs-nav-list {
    padding: 8px 0;
  }
}

.skeleton-sidebar-wrapper {
  padding: 24px;
  overflow: hidden;

  .skeleton-sidebar-item-group {
    border-right: 2px solid #f0f0f0;
    padding-right: 24px;

    .skeleton-sidebar-item {
      margin-bottom: 12px;
    }
  }

  .skeleton-sidebar-content-wrapper {
    .skeleton-sidebar-content-header {
      display: flex;

      &-button {
        flex: 1;
      }

      &-button:not(:last-child),
      &-input {
        margin-right: 24px;
      }
    }

    .skeleton-sidebar-content-body {
      margin: 24px 0;
    }
  }
}

.overflow-tag {
  &:not(:hover) {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover {
    opacity: 1;
    z-index: 19;
  }
}

.navlink-cell {
  white-space: nowrap;
  z-index: 19;

  &:not(:hover) {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover {
    background-color: #fafafa;
    padding-right: 4px;
  }
}

.simple-table {
  width: 100%;

  &,
  th,
  td {
    border: 1px solid #f0f0f0;
  }

  th,
  td {
    padding: 8px;
  }
}

.no-style-button {
  padding: 0;
  height: 23px;
}

.info-actions {
  margin-bottom: 20px;
  display: flex;

  .info-actions-button {
    overflow: hidden;
    text-overflow: ellipsis;

    &:not(:last-child) {
      margin-right: 12px;
    }
  }
}

.link-list {
  .link-list-item {
    color: #ffffff;
    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}

.ellipsis-cell {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.overflow-cell {
  background: #fafafa;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  z-index: 2;

  &:hover {
    overflow: unset;
    text-overflow: unset;
    white-space: nowrap;
    padding-right: 4px;
  }
}

.cell-button {
  margin: -5px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.user-info-popover {
  .ant-popover-inner-content {
    padding: 0;
  }

  &-body {
    margin-top: 8px;
  }

  &-footer {
    margin-top: 8px;
  }
}

@keyframes loading-animation {
  0% {
    transform: translateX(-100px) scaleX(1);
  }

  48% {
    transform: translateX(96px) scaleX(1);
  }

  50% {
    transform: translateX(100px) scaleX(-1);
  }

  96% {
    transform: translateX(-96px) scaleX(-1);
  }

  100% {
    transform: translateX(-100px) scaleX(1);
  }
}

.loading-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fafafa;
  display: flex;
  justify-content: center;
  align-items: center;
  & > img {
    height: 96px;
    animation: loading-animation 10s linear infinite;
  }
}

.gray-menu-badge {
  color: unset !important;

  .ant-badge-count {
    background-color: #1da57a;
    color: #ffffff;
    box-shadow: 0 0 0 1px #d9d9d9 inset;
  }
}

.notifications-card {
  min-width: 300px;

  .ant-card-head-wrapper {
    .ant-card-head-title {
      text-align: center;
    }
  }

  .ant-card-body {
    padding: 0;
  }

  &-item {
    display: block;
    padding: 0 12px;
    transition: background-color 0.3s;

    &:hover {
      background-color: rgba(#39b187, 0.3);
    }
  }
}

.full-width-modal {
  width: calc(100% - 48px) !important;
}

.model-statistics-modal {
  .ant-modal-body {
    padding: 0 8px;
  }
}

.model-statistics-progress {
  .dashboard-progress-card-text-title {
    font-size: 24px;
  }
}

.modal-title-with-icon {
  display: flex;
  justify-content: space-between;
}

.base-page-header {
  padding: 8px;
  margin-top: -8px;
  border-bottom: 1px solid #f0f0f0;

  .ant-page-header-heading-title {
    font-size: 16px;
    line-height: 17px;
  }
}

.bottom-margin-free {
  margin-bottom: 0 !important;
}

.masonry-grid {
  columns: 6 300px;
  column-gap: 16px;

  .masonry-col {
    display: inline-block;
    width: 100%;
    padding-bottom: 16px;
  }
}

.ant-table-col-100 {
  min-width: 100px;
}

.ant-table-col-175 {
  min-width: 175px;
}

.ant-table-next {
  th {
    text-align: center !important;
  }
}
