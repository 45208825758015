.quick-search-user-descriptions {
  & > .ant-descriptions-title {
    margin-bottom: 8px;
  }
}

.quick-search-divider {
  margin: 8px 0;
}

.quick-search-tabs {
  flex: 1;

  .ant-tabs-tabpane {
    padding-left: 8px !important;
  }
}
